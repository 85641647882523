import createTheme from "@mui/material/styles/createTheme";

const theme: any = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffb250",
    },
    secondary: {
      main: "#50f5ff",
    },
  },
  spacing: 4,
  typography: {
    h1: {
      fontFamily: "'The Wild Breath of Zelda', sans-serif",
    },
    h2: {
      fontFamily: "'The Wild Breath of Zelda', sans-serif",
    },
    h3: {
      fontFamily: "'The Wild Breath of Zelda', sans-serif",
    },
    h4: {
      fontFamily: "'The Wild Breath of Zelda', sans-serif",
    },
  },
});
export default theme;
