export interface ITimeline {
  title: string;
  summary: string;
  year: string;
}

export const timeline: ITimeline[] = [
  {
    title: "Pre-History",
    summary: `It's important to note that the nature of this era before the separation of the planes is shrouded in mystery and often veiled in myth and metaphor. The formation of the prime material plane is a subject of philosophical speculation, religious beliefs, and arcane theories across various cultures and planes of existence, each providing their own unique interpretations of this primordial epoch.
    However, there are commonalities in the creation myths of the various cultures that may shead some light on the origins of Teratin. One of these is that this was a time there was no such thing as the many planes of existance that we know now. In fact, it's believed that there was only one plane of roiling elemental energy. As the planes started to seperate the energy sources for these elemental forces began to recede. 
    `,
    year: "circa 100,000 PF",
  },
  {
    title: "The Dawn",
    summary: `The "Dawn of Consciousness" marks a profound and pivotal epoch in the cosmic history, following the formation of the prime material plane and the establishment of the foundational realms. During this epoch, sentient awareness emerged, bringing with it the birth of individual and collective consciousness across the multiverse.

    At the outset of this epoch, the prime material plane and its myriad realms were inhabited by an array of life forms, ranging from simple organisms to complex and intelligent beings. These beings were initially driven by instinct and primal urges, responding to the rhythms of the natural world. However, a subtle and transformative shift occurred as certain species began to exhibit signs of self-awareness and introspection.
    
    The emergence of consciousness was a gradual and intricate process, fueled by a combination of environmental pressures, genetic mutations, and the interplay of cosmic energies. Over countless generations, beings developed a heightened capacity for perception, cognition, and self-reflection. This newfound awareness allowed them to contemplate their surroundings, ponder their own existence, and even delve into the mysteries of the cosmos.`,
    year: "circa 50,000 PF",
  },
  {
    title: "The Age of Dreki",
    summary: `During this time the dominant race on Tordenhelm, and Teratin in its entirety, was dragons. Unlike the stories of dragons today dragons in this time lived communally had a generally gerontocratic (The oldest lead) leadership structure.
    Although immortal, their motivations would be understandable to mortals of today they hunted, farmed and had children. They sort to make the world a better place for their offspring.
    Unlike today the planes were far closer together so the dragons regularly had to band together to fight wars against fiendish and elemental incursions forcing dragon society to be quite militaristic but they longed for prolonged peace.
    Dragon’s were often brought into the schemes of deities that during this time wondered the lands.`,
    year: "circa 3,000 PF",
  },
  {
    title: "The Fall of Dragonkind",
    summary: `It’s not certain when this originally happened but it is presumed it was a plan centries in the making. Lovlig infiltrated dragon society and bore offspring that in turn borne a new race of dragons. They bided their time until many of the great heroes of the previous generations had fallen in battle. Then they struck revolting against their parents and their rulership, slaughtering all that were not cursed such as them.
    This new bread of dragon was greedy and isolationist. They had a singleminded goal of acquiring wealth at all costs. So after initially working together to throw off their parents rule they turned on each other.
    This blood curse was incurable and none of the unaffiliated older dragons knew who among them were carriers of the it. They begged the gods to remove the affliction from their bloodlines once and for all. One God Villious the Arch-deity of life and stability took pity on them and did what they could.
    The next line of dragons would be free of the curse but would give up their greatest gift… their immortality. The remaining dragons agreed, and some of those that had living mates bore children in smaller eggs these were the first dragonborne.
    What became of the remaining dragons is now lost to history but I is believed by some they are still out there or that they were eventually hunted down and killed by their children vengeful children.
    `,
    year: "circa 1,000 PF",
  },
  {
    title: "The Dark Covergence",
    summary: `The fall of dragonkind created a power vacuum that destabilized the delicate balance of the multiverse. As a result, the barriers between planes of existence weakened, leading to frequent and unpredictable breaches between the material plane and other dimensions. These breaches allowed malevolent entities, extraplanar creatures, and elemental forces to spill into the prime material plane, wreaking havoc upon its landscapes and inhabitants.
    Unchecked, fiends used Teratin as a soul farm for their soul engines. Subjugation of the sapient mortal races was almost total.
    `,
    year: "circa 900 PF",
  },
  {
    title: "Ascendant Heroes",
    summary: `During the Epoch of Ascendant Heroes, the prime material plane witnessed an inspiring saga of courage, sacrifice, and unwavering determination. The heroes' actions not only liberated their world from the grip of interplanar oppression but also forever altered the cosmic balance, as they themselves transcended mortal limitations to become deities.`,
    year: "circa 100 PF",
  },
  {
    title: "The Forging",
    summary: `The After the Five ascended the Forging of The Imperium took place. In their honour this society was structured to match their individual strengths creating a cast system known as “alignment”. The first Empero signed the Pact Mortalis into law providing rights to the citizens of the imperium. `,
    year: "0",
  },
  {
    title: "Age of Compliance",
    summary: `Often refered too as "The Dark Times". The Empero declared that all of Tordenhelm must be brought into compliance with Imperial Law. The Old Gods before this time were respected, the Tip Lords ruled in their Dominions free of Imperial interference. The Nals project was fast tracked using the newly found blasphemous as prison labour to complete them. This was met with sometimes disastrous results. The flooding of Shamphel (Now Shambles). The Wild Lands wars saw the complete breakdown of relationship between the Moving Cities and Torden. To the point that the Empire denies their existence to this very day`,
    year: "404 AF",
  },
];
